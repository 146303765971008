<template>
  <AppLayout>
    <div class="h-ful border-t border-b" v-if="!loading">
      <CalendarEventModal
          v-if="isOpenModal"
          listTitle="Event"
          :selectedEvent="selectedEvent"
          :selectedEventStatus="selectedEventStatus"
          :formErrors="formErrors"
          @handleCloseModal="handleCloseModal"
          @removeFormErrorsItem="removeFormErrorsItem"
      />
      <div class="md:grid md:grid-cols-1 md:gap-6 mt-5 px-6">
        <FullCalendar :options="calendarOptions" ref="calendar"></FullCalendar>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/Layout/AppLayout';
import { mapActions, mapGetters } from 'vuex';
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarEventModal from '@/components/Modals/CalendarEventModal/CalendarEventModal';
import enCalendarLocale from '@fullcalendar/core/locales/en-gb'
import fiCalendarLocale from '@fullcalendar/core/locales/fi'

export default {
  name: 'Calendar',
  components: {
    CalendarEventModal,
    AppLayout,
    FullCalendar
  },
  data() {
    return {
      loading: true,
      isOpenModal: false,
      selectedEvent: {},
      selectedEventStatus: '',
      selectedCheckListItemId: '',
      formErrors: {},
      calendarLocales: {
        fi: fiCalendarLocale,
        en: enCalendarLocale
      },
      calendarOptions: {
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        locale: localStorage.getItem('locale'),
        events: [],
        allDaySlot: true,
        allDayText: 'All day',
        slotLabelFormat: [
          {
            hour: '2-digit',
            minute: '2-digit',
            hour12:false
          }
        ]

      }
    }
  },
  computed: {
    ...mapGetters([
        'events',
        'updatedTargetEvent',
        'updateEventSuccess',
        'updateEventError',
        'updateEventRequestMessage',
        'eventProcessSuccess',
        'eventProcessError',
        'eventProcessRequestMessage',
        'syncCalendarEventsSuccess',
        'syncCalendarEventsError',
        'syncCalendarEventsResponseMessage',
        'closeEventModal',
        'updatedTargetEvent'
    ])
  },
  watch: {
    events(newValue) {
      this.calendarOptions.events = newValue;
    },
    updateEventSuccess(newValue) {
      if (newValue) {
        this.modifyEventAfterRequest(this.updateEventRequestMessage);
        this.handleCloseModal();
      }
    },
    updatedTargetEvent(val){
      if (val.status){
        this.selectedEventStatus = val.status
      }
    },
    updateEventError(newValue) {
      if (newValue) {
        const { message, errors } = this.updateEventRequestMessage;
        this.formErrors = errors;
        this.toastMessage(message, 'error');
      }
    },
    eventProcessSuccess(newValue) {
      if (newValue) {
        this.modifyEventAfterRequest(this.eventProcessRequestMessage);
        this.closeEventModal && this.handleCloseModal();
      }
    },

    eventProcessError(newValue) {
      if (newValue) {
        const { message, errors } = this.eventProcessRequestMessage;
        this.formErrors = errors;
        this.toastMessage(message, 'error');
      }
    },
    syncCalendarEventsSuccess(newValue) {
      newValue && this.toastMessage(this.syncCalendarEventsResponseMessage);
    },
    syncCalendarEventsError(newValue) {
      newValue && this.toastMessage(this.syncCalendarEventsResponseMessage, 'error');
    }
  },
  mounted() {
    this.calendarOptions.locale = this.calendarLocales[localStorage.getItem('locale')]
    this.fetchCalendarEvents();
    const self = this;
    this.calendarOptions.eventClick = function(clickInfo) {
      self.selectedEvent = clickInfo.event;
      self.selectedEventStatus = clickInfo.event._def.extendedProps.status;
      self.onEvent();
    };
    this.loading = false;
  },
  methods: {
    ...mapActions(['fetchCalendarEvents']),
    onEvent() {
      this.isOpenModal = !this.isOpenModal;
    },
    modifyEventAfterRequest(message) {
      const eventCopy = [...this.calendarOptions.events];
      this.calendarOptions.events = [];
      eventCopy.filter((event, item) => {
        if (event.id === this.updatedTargetEvent.id) {
          eventCopy[item] = this.updatedTargetEvent;
        }
      });
      this.calendarOptions.events = eventCopy;
      this.toastMessage(message);
    },
    removeFormErrorsItem(item) {
      this.formErrors[item] = [];
    },
    handleCloseModal() {
      this.isOpenModal = false;
      this.formErrors = {};
    },
    toastMessage(message, type = 'success') {
      this.$toast.clear();
      this.$toast.open({ message, type })
    }
  }
}
</script>

<style>
.fc-button-primary:focus {
  outline: unset !important;
  box-shadow: unset !important;
}
/*.fc-daygrid-event-harness{*/
/*  background-color: #ffdd57;*/
/*  padding: 5px 0;*/
/*}*/



@media screen and (max-width: 600px) {
.fc-toolbar-chunk{
   display: flex !important;
   flex-direction: column;
   gap: 10px;
  }

  .fc-button-group{
     display: flex !important;
   flex-direction: column;
   gap: 10px;
   align-items: start;
  }

  .fc-today-button{
    margin-left: -10px !important;
  }

/* .fc-button{
 width: 40px !important;
} */
}

.fc-event-title{
 width: 100% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.fc-button-primary {
  height: 39px !important;
  background: #132c40 !important;
  border-radius:7px !important;
}

.fc-button-primary:hover {
  opacity: 0.7 !important;
}

.fc-button-primary:focus {
  opacity: 0.7 !important;
}

.fc-prev-button,
.fc-dayGridMonth-button,
.fc-timeGridWeek-button {
  margin-right: 15px !important;
  border-radius: 0.375rem !important;
}

</style>
