<template>
  <div>
    <div class="w-full flex items-start justify-start flex-col gap-2">
      <label class="text-sm font-medium text-gray-500">{{ $t('checkList.item.selectOptionTitle') }}</label>
      <div class="w-full relative" v-click-outside="closeDropdown">
        <div class="w-full">
          <input
              @click="isOpen = !isOpen"
              @input="handleSearch"
              v-model="searchValue"
              type="text"
              autocomplete="off"
              class="w-full cursor-pointer h-formInput bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 sm:text-sm">
          <FormInputArrowIcon />
        </div>
        <div class="absolute w-full rounded-md bg-white">
          <ul v-if="isOpen && checkListItems.length"
              class="absolute z-50 w-full bg-white max-h-60 rounded-md py-1 ring-opacity-5 overflow-auto focus:outline-none text-sm border border-gray-300">
            <li v-for="item in searchedCheckListItems"
                :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                         'text-white bg-primary': item.id === selectedItem.id,
                         'text-gray-900 hover:bg-gray-50': item.id !== selectedItem.id
                }]"
                :key="item.id"
                @click="handleSelectCheckListItem(item.id)"
            >
              <span class="font-normal block truncate">{{ item.item }}</span>
              <span
                  v-if="item.id === selectedItem.id"
                  class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                     :fill="item.id === selectedItem.id ? 'white' : 'currentColor'">
                  <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"/>
                </svg>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'CheckListItemSelectOption',
  components: { FormInputArrowIcon },
  directives: {
    ClickOutside
  },
  props: {
    checkListItems: {
      type: Array,
      'default': Array,
      required: true
    },
    defaultCheckListItemId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      searchValue: '',
      selectedItem: {}
    }
  },
  computed: {
    searchedCheckListItems() {
      return !this.searchValue ? this.checkListItems : this.checkListItems.filter(checkListItem => {
        if (checkListItem.item?.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return checkListItem;
        }
      });
    }
  },
  mounted() {
    this.defaultCheckListItemId && this.setDefaultValue();
    this.$root.$on('emptySearchValue', () => {
      this.searchValue = '';
    })
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleSearch() {
      this.isOpen = true;
    },
    setDefaultValue() {
      this.selectedItem = this.checkListItems.find(list => String(list.id) === this.defaultCheckListItemId);
      this.searchValue = this.selectedItem.item;
    },
    handleSelectCheckListItem(id) {
      this.selectedItem = this.checkListItems.find(item => item.id === id);
      this.searchValue = this.selectedItem.item;
      this.isOpen = false;
      this.$emit('handleChangeCheckListItem', id);
    }
  }
}
</script>

<style scoped>

</style>
