<template>
  <div class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true" @click="$emit('handleCloseModal')">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
          class="calendar-modal inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-prose sm:w-full sm:p-6"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="flex flex-col justify-between h-full">
          <div>
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  {{ listTitle }}
                </h3>
              </div>
            </div>
            <div :class="selectedEventStatus!=='ignore' ? ' overflow-y-scroll h-600p calendar-body my-5': 'overflow-y-scroll h-600p calendar-body my-5' ">
              <div class=" flex mt-5">
                <button type="button" @click="changeStatus" :class="[enabled ? 'bg-primary' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none   ']">
                  <span class="sr-only">Use setting</span>
                  <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" ></span>
                </button>
                <label class="text-sm font-medium text-gray-500 ml-5">{{ $t('calendar.eventModal.privateEvent') }}</label>
              </div>
              <div v-if="!loading && !enabled" class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 mt-5">
                <div class="sm:col-span-1">
                  <CustomersOptions
                      :defaultValue="String(form.customer_id)"
                      :defaultCustomerName="form.customerName"
                      :editable="true"
                      :isPublicPage="false"
                      fieldTitle="calendar.eventModal.customerTitle"
                      :errorMessage="formErrors.customer_id"
                      @handleInputFieldChange="handleChangeCustomer"
                  />
                </div>
                <div class="sm:col-span-1">
                  <TasksSelectOption
                      :tasks="tasks"
                      :defaultTask="initialState.task"
                      :errorMessage="formErrors.task_id"
                      @handleChangeTask="handleChangeTask"
                  />
                </div>
                <div class="sm:col-span-1" v-if="selectedTask.id && selectedTask.checklists.length">
                  <CheckListSelectOption
                      :checkLists="selectedTask.checklists"
                      :defaultCheckList="initialState.checklist"
                      :errorMessage="formErrors.checklist_id"
                      @handleChangeCheckList="handleChangeCheckList"
                  />
                </div>
                <div class="sm:col-span-1" v-if="selectedTask.id && selectedTask.checklists.length">
                  <CheckListItemSelectOption
                      :checkListItems="selectedCheckList.items"
                      :defaultCheckListItemId="String(form.checklist_item_id)"
                      @handleChangeCheckListItem="handleChangeCheckListItem($event)"
                  />
                </div>
                <div class="sm:col-span-1">
                  <ProductsOption
                      :defaultProduct="initialState.product"
                      :errorMessage="formErrors.product_id"
                      @handleChangeProduct="handleChangeProduct"
                  />
                </div>
                <div class="sm:col-span-1">
                  <DeliveredBySelectOption
                      :defaultDeliver="initialState.deliver"
                      label="Delivered by"
                      :errorMessage="formErrors.delivered_by_id"
                      @handleChangeDeliver="handleChangeDeliver"
                  />
                </div>
                <div class="sm:col-span-1">
                  <DatePicker
                      :defaultValue="form.due_date"
                      :editable="true"
                      @handleInputFieldChange="form.due_date = $event.value"
                      inputName="due_date"
                  />
                </div>
                <div class="sm:col-span-1">
                  <FormInput
                      :label="$t('task.fields.description')"
                      :value="form.description"
                      inputName="description"
                      :errorMessage="formErrors.description"
                      @handleBlurChange="handleInputChange"
                  />
                </div>
                <div class="sm:col-span-1">
                  <FormInput
                      :value="form.quantity"
                      label="Quantity"
                      inputName="quantity"
                      type="number"
                      :errorMessage="formErrors.quantity"
                      @handleBlurChange="handleInputChange"
                  />
                </div>
                <div class="sm:col-span-1">
                  <FormInput
                      :value="form.unit"
                      label="Unit"
                      inputName="unit"
                      :errorMessage="formErrors.unit"
                      @handleBlurChange="handleInputChange"
                  />
                </div>
                <div class="sm:col-span-1">
                  <FormInput
                      :value="form.unit_price"
                      label="Unit price"
                      inputName="unit_price"
                      type="number"
                      :errorMessage="formErrors.unit_price"
                      @handleBlurChange="handleInputChange"
                  />
                </div>
                <div class="sm:col-span-1">
                  <VatOptions
                      :editable="true"
                      :selectedVat="selectedVat"
                      @selectVat="selectVat"
                  />
                </div>
              </div>
              <div v-if="!loading && !enabled" class="mt-5 grid grid-cols-1">
                <InvoiceTotal :total="total" v-if="Object.keys(total).length"/>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-4 flex justify-end" :class="!enabled ? 'pb-6': ''">
            <div class="mr-1">
              <MainButton
                  @clickAction="$emit('handleCloseModal')"
                  name="Close"
                  bgStyle="light"
              />
            </div>
            <div>
              <MainButton
                  name="Save"
                  @clickAction="handleSaveForm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import ProductsOption from '@/components/SelectOptions/ProductsOption/ProductsOption';
import vatOptions from '@/helpers/vatOptions';
import DeliveredBySelectOption from '@/components/SelectOptions/DeliveredBySelectOption/DeliveredBySelectOption';
import DatePicker from '@/components/DatePicker/DatePicker';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import VatOptions from '@/components/SelectOptions/VatOptions/VatOptions';
import InvoiceTotal from '@/components/Task/InvoiceTotal';
import CustomersOptions from '@/components/SelectOptions/CustomersOptions/CustomersOptions';
import TasksSelectOption from '@/components/SelectOptions/TasksSelectOption/TasksSelectOption';
import CheckListSelectOption from '@/components/SelectOptions/CheckListSelectOption/CheckListSelectOption';
import CheckListItemSelectOption from '@/components/SelectOptions/CheckListItemSelectOption/CheckListItemSelectOption';

export default {
  name: 'CalendarEventModal',
  components: {
    CheckListItemSelectOption,
    CheckListSelectOption,
    TasksSelectOption,
    CustomersOptions,
    InvoiceTotal,
    VatOptions,
    FormInput,
    DatePicker,
    DeliveredBySelectOption,
    ProductsOption,
    MainButton
  },
  props: {
    listTitle: {
      type: String,
      required: true
    },
    selectedEventStatus: {
      type: String,
      required: true
    },
    selectedEvent: {
      type: Object,
      required: true
    },
    formErrors: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: {},
      initialState: {
        task: {},
        product: {},
        checklist: {},
        deliver: {}
      },
      selectedVat: vatOptions[0],
      tasks: [],
      selectedTask: {},
      selectedCheckList: {},
      total: {},
      form: {
        checklist_id: '',
        checklist_item_id: '',
        customer_id: '',
        customerName: '',
        delivered_by_id: '',
        description: '',
        product_id: '',
        quantity: '',
        task_id: '',
        unit: '',
        unit_price: '',
        vat: vatOptions[0].value,
        delivered_at: moment(Date.now()).format('YYYY-M-DD'),
        due_date: ''
      },
      enabled: false
    }
  },
  computed: {
    ...mapGetters(['customersTasks', 'invoiceLineResponseMessage', 'invoiceLine', 'updateInvoiceLineSuccess', 'updatedTargetEvent', 'updateEventSuccess', 'eventProcessSuccess'])
  },
  watch: {
    customersTasks(newValue) {
      this.tasks = newValue;
    },
    'initialState.task'(newVal) {
      this.selectedTask = newVal;
    },
    'initialState.checklist'(newVal) {
      this.selectedCheckList = newVal;
    },
    selectedCheckList(val){
      this.addNewChecklistItems(val)
    },
    updateInvoiceLineSuccess(val){
      val && this.updateInvoiceLine(this.invoiceLine)
      val && this.toastMessage(this.invoiceLineResponseMessage, 'success');
    },
    eventProcessSuccess(val){
      val && this.setDefaultData(this.updatedTargetEvent.item)
    }
  },
  mounted() {
    if (this.selectedEventStatus === 'processed'){
      this.setDefaultData()
    } else {
      this.loading = false
      this.addNewChecklistItems(this.selectedCheckList)
    }
    this.enabled = this.selectedEventStatus==='ignore'
  },
  methods: {
    ...mapActions([
      'fetchGetTasksByCustomerId',
      'fetchEventProcess',
      'fetchUpdateEventProcess',
      'fetchUpdateEventStatus',
       'fetchCreateInvoiceLine'
    ]),
    click(){
    },
    handleChangeCheckListItem(value){
      this.form.checklist_item_id = value
    },
    addNewChecklistItems(value){
      const item = { checklist_id: null,
        done: 0,
        id: -1,
        invoice_line: null,
        item: null }
      // if (value.items !==undefined) {
      //   const newItem = value.items.find(item => item.id === -1)
      //   if (newItem===undefined){
      //     value.items.unshift(item);
      //   }
      // } else {
      //   value.items =[item]
      //   this.form.checklist_item_id = -1;
      // }

    },
    setDefaultData(item) {
      this.data = item ?? this.selectedEvent._def.extendedProps.item;
      this.form.customer_id = this.data?.checklist.task.customer.id;
      this.form.customerName = this.data?.checklist.task.customer.name;
      this.form.task_id = this.data?.checklist.task.id;
      this.form.checklist_id = this.data?.checklist.id;
      this.form.checklist_item_id = this.data?.id;
      if (this.data?.invoice_line) {
         this.updateInvoiceLine(this.data.invoice_line)
      }
      this.initialState.task = this.data?.checklist.task;
      this.initialState.checklist = this.data?.checklist;
      this.loading = false;
    },
    updateInvoiceLine(data){
      this.form.product_id = data.product.id;
      this.form.delivered_by_id = data.delivered_by.id;
      this.form.description = data.description;
      this.form.quantity = data.quantity;
      this.form.unit = data.unit;
      this.form.unit_price = data.unit_price;
      this.form.vat = data.vat;
      this.form.due_date = data.due_date;

      this.selectedVat = vatOptions.find(option => String(option.value) === String(data.vat));
      this.form.vat = data.vat;
      this.initialState.product = data.product;
      this.initialState.deliver = data.delivered_by;
      this.total = data.total;
    },
    handleChangeCustomer({ value }) {
      this.form.customer_id = value;
      value && this.fetchGetTasksByCustomerId(value);
      this.emitToRemoveErrorsItem('customer_id');
      this.$root.$emit('emptySearchValue');
    },
    handleChangeTask(selectedTask) {
      this.selectedTask = selectedTask;
      this.form.task_id = selectedTask.id;
      this.emitToRemoveErrorsItem('task_id');
    },
    handleChangeCheckList(checkList) {
      this.selectedCheckList = checkList;
      this.form.checklist_id = checkList.id;
      this.emitToRemoveErrorsItem('checklist_id');
    },
    handleChangeProduct(product) {
      this.form.product_id = product.id;
      this.form.unit_price = product.price;
      this.form.unit = product.unit.length>0 ? product.unit : this.form.unit;
      this.form.quantity = 1;
      this.selectedVat = vatOptions.find(option => String(option.value) === String(product.vat));
      this.form.vat = product.vat;
      this.emitToRemoveErrorsItem('product_id');
      this.updateInvoiceLineEvent()
    },
    updateInvoiceLineEvent(){
      if (this.selectedEventStatus === 'processed'){
        this.fetchCreateInvoiceLine({ id: this.form.checklist_item_id, form: this.form });
      }/* else {
        this.$store.commit('setOpenEventModal')
        this.saveEvent()
      }*/
    },
    handleChangeDeliver({ id }) {
      this.form.delivered_by_id = id;
      this.emitToRemoveErrorsItem('delivered_by_id');
      this.updateInvoiceLineEvent()
    },
    handleInputChange(inputName, value) {
      this.form[inputName] = value;
      this.emitToRemoveErrorsItem(inputName);
      this.updateInvoiceLineEvent()
    },
    emitToRemoveErrorsItem(key) {
      this.$emit('removeFormErrorsItem', key);
    },
    selectVat(vat) {
      this.form.vat = vat.value;
      this.selectedVat = vat;
      this.updateInvoiceLineEvent()
    },
    handleSaveForm() {
     this.$store.commit('setCloseEventModal')
     this.saveEvent()
    },
    saveEvent(){
      const data = { id: this.selectedEvent.id, form: this.form };
      if (this.selectedEventStatus === 'processed') {
        data.form.invoice_line_id = this.data.invoice_line.id;
        this.fetchUpdateEventProcess({ ...data })
      } else {
        this.fetchEventProcess({ ...data });
      }
       const dataStatus = { id: this.selectedEvent.id, eventStatus: this.enabled ? 'ignore' : 'new' };
       this.fetchUpdateEventStatus({ ...dataStatus });
    },
    changeStatus() {
      this.enabled = !this.enabled;
    },
    toastMessage(message, type = 'success') {
      this.$toast.clear();
      this.$toast.open({ message, type })
    }
  }
}
</script>

<style scoped>
.calendar-body::-webkit-scrollbar {
  width: 5px;
}

.calendar-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

</style>
